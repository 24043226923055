<template>
	<section>
		<TnVideo
			:src-desktop="video.landscapeVideoLink"
			:src-mobile="video.portraitVideoLink"
			:src-youtube="video.url"
			:muted="video.muted"
			:loop="video.loop"
			:controls="!video.disableControls"
			:autoplay="video.autoplay"
			:poster="video.stillFrame && video.stillFrame.src"
			:dark="videoIconColor"
		></TnVideo>
		<TnHeading
			v-if="video.caption"
			type="h3"
			center
			>{{ video.caption }}</TnHeading
		>
	</section>
</template>

<script>
import GizmoComponentMixin from "~/mixins/platform/GizmoComponentMixin.js";

export default defineNuxtComponent({
	name: "Video",

	mixins: [GizmoComponentMixin],

	computed: {
		video() {
			return this.component.content;
		},
		videoIconColor() {
			return !!(!this.video?.playButtonColor || this.video?.playButtonColor?.toLowerCase() === "white");
		},
	},

	mounted() {
		if (useRoute().hash) {
			setTimeout(() => (location.href = useRoute().hash), 1);
		}
	},
});
</script>
